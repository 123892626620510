<template>
    <div class="main-contents code oper unit">
        <div class="tit">능력단위요소 등록/수정</div>
        <div class="search-box interval">
            <SelectComp class="wd09" :list="midNameList" codeKey="midClassCd" nameKey="midClassNm" v-model="srchFilter.ncsMidSeq" :isAll="true" title="중분류" @change="loadMinor()" />&nbsp; 
            <SelectComp class="wd09" :list="minorNameList" codeKey="minorClassCd" nameKey="minorClassNm" v-model="srchFilter.ncsMinorSeq" :isAll="true" title="소분류" @change="loadDetail()" />&nbsp; 
            <SelectComp class="wd09" :list="detailNameList" codeKey="detailClassCd" nameKey="detailClassNm" v-model="srchFilter.ncsDetaSeq" :isAll="true" title="세분류" @change="loadAbil()" />&nbsp; 
            <SelectComp class="wd09" :list="abilNameList" codeKey="abilUnitCd" nameKey="abilUnitNm" v-model="srchFilter.ncsAbilSeq" :isAll="true" title="능력단위" />&nbsp; 
            <SelectComp class="wd03" list="Y:사용,N:사용안함" v-model="srchFilter.srchUse" :isAll="true" title="사용여부"/>&nbsp; 
            <SelectComp class="wd09" list="name:능력단위요소명,cd:능력단위요소코드" v-model="srchFilter.srchType" @change="placeholderTxt = $event.target.selectedOptions[0].label" title="능력단위요소명"/> 
            <InputComp v-model="srchFilter.srchTxt" :placeholder="placeholderTxt" @keyup.enter="getAbilUnitElemList('1'), check(srchFilter.ncsMidSeq), check1(srchFilter.ncsMinorSeq), check2(srchFilter.ncsDetaSeq), check3(srchFilter.ncsAbilSeq)" />
            <img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getAbilUnitElemList('1'), check(srchFilter.ncsMidSeq), check1(srchFilter.ncsMinorSeq), check2(srchFilter.ncsDetaSeq), check3(srchFilter.ncsAbilSeq)"/>
            <div class="sub_btn" @click="$router.push('MAN936M05')">
                + 등록
            </div>
        </div>
        <!-- 능력단위 등록/수정 리스트 -->
        <div class="Board type3">
            <div class="cf_title">5. 능력단위요소명을 선택하세요</div>
            <div class="catg" v-if="this.midName == ''"></div>
            <div class="catg" v-else>[정보통신] {{this.midName}}{{this.minorName}}{{this.detailName}}{{this.abilName}}</div>
            <table class="Board_type3 admin">
                <colgroup>
                    <col width="4%">
                    <col width="9%">
                    <col width="12%">
                    <col width="12%">
                    <col width="12%">
                    <col width="12%">
                    <col width="12%">
                    <col width="14%">
                    <col width="5%">
                    <col width="8%">
                </colgroup> 
                <thead>
                    <tr>
                        <th>NO</th>
                        <th>[대분류명]</th>
                        <th>1.중분류명</th>
                        <th>2.소분류명</th>
                        <th>3.세분류명</th>
                        <th>4.능력단위명</th>
                        <th>능력단위코드</th>
                        <th>5.능력단위요소명</th>
                        <th>사용<br>여부</th>
                        <th>수정</th>
                    </tr>
                </thead>
                <tbody v-if='abilUnitElemList.length > 0'>
                    <tr v-for="(elem, elemindex) in abilUnitElemList" :key="elemindex">
                        <td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (elemindex+1)}}</td>
                        <td class="score">{{elem.majorClassNm}}</td>
                        <td class="score">{{elem.midClassNm}}</td>
                        <td class="score">{{elem.minorClassNm}}</td>
                        <td class="score">{{elem.detailClassNm}}</td>
                        <td class="score">{{elem.abilUnitNm}}</td>
                        <td class="score">{{elem.abilUnitElemCd}}</td>
                        <td class="score name" @click="viewSelfDiaQues(elem)">{{elem.abilUnitElemNm}} &#9654;</td>
                        <td class="score">{{elem.useYn}}</td>
                        <td class="score name" @click="viewAbilElemDetail(elem)"><img src="/images/admin/cf_modify_btn.png" alt="수정버튼"/></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <!-- 관련내용 없을 경우 -->
                    <tr class="none">
                        <td colspan="10" class="none">등록된 능력단위요소항목이 없습니다!</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 화면 up & down 버튼 -->
        <div class="screen_btn">
            <img src="/images/admin/screen-top_btn.png" alt="탑버튼"/>
            <img src="/images/admin/screen-down_btn.png" alt="다운버튼"/>
        </div>
        <!-- 페이징부분 -->
        <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
    </div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
import SelectComp from '@/components/base/SelectComp.vue';

export default {
    data() {
        return {

            ncsMajorSeq : this.$route.params.ncsMajorSeq || '',
            ncsMidSeq : this.$route.params.ncsMidSeq || '',
            ncsMinorSeq : this.$route.params.ncsMinorSeq || '',
            ncsDetaSeq : this.$route.params.ncsDetaSeq || '',
            ncsAbilSeq : this.$route.params.ncsAbilSeq || '',
            midName : this.$route.params.midName || '' ,
            minorName : this.$route.params.minorName || '',
            detailName : this.$route.params.detailName || '',
            abilName : this.$route.params.abilName || '',
            
            //검색조건
			srchFilter : {
                ncsMajorSeq : '20',
				ncsMidSeq : '',
                ncsMinorSeq : '',
                ncsDetaSeq : '',
                ncsAbilSeq : '',
                srchUse : '',
                srchType : 'name',
				srchTxt : '',
				pageIndex : 1,
                midName : '',
                minorName : '',
                detailName : '',
                abilName : '',
			},

            abilUnitElemList : [],

            midNameList : [],
            minorNameList : [],
            detailNameList : [],
            abilNameList : [],

            placeholderTxt : '능력단위요소명',

            //페이징
			pageInfo : {},

        }
    },

    mounted() {
		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key)>=0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}

        this.getAbilUnitElemList();
	},

    beforeMount() {
        this.loadMid();
        if(this.ncsAbilSeq != '') {
            
            this.loadMid();
            // this.getAbilUnitElemList();
            // this.loadMinor();
            // this.loadDetail();
            // this.loadAbil();
            // this.ncsAbilSeq = '';
            if(this.midName != '') {
                this.srchFilter.midName = this.midName;
            }
            if(this.minorName != '') {
                this.srchFilter.minorName = this.minorName;    
            }
            if(this.detailName != '') {
                this.srchFilter.detailName = this.detailName;    
            }
            if(this.abilName != '') {
                this.srchFilter.abilName = this.abilName;    
            }
        }
        
        // if(this.srchFilter.midName != '') {
        //     this.midName = this.srchFilter.midName;
        // }
        // if(this.srchFilter.minorName != '') {
        //     this.minorName = this.srchFilter.minorName;    
        // }
        // if(this.srchFilter.detailName != '') {
        //     this.detailName = this.srchFilter.detailName;    
        // }
        // if(this.srchFilter.abilName != '') {
        //     this.abilName = this.srchFilter.abilName;    
        // }

        // console.log('beforeMount', this.srchFilter);
        
	},

    methods: {

        getAbilUnitElemList(div) {
            var param = {};
            if(this.ncsAbilSeq != '') {
                this.srchFilter.ncsMajorSeq = this.ncsMajorSeq;
                this.srchFilter.ncsMidSeq = this.ncsMidSeq;
                this.srchFilter.ncsMinorSeq = this.ncsMinorSeq;
                this.srchFilter.ncsDetaSeq = this.ncsDetaSeq;
                this.srchFilter.ncsAbilSeq = this.ncsAbilSeq;
                this.ncsAbilSeq = '';

                // this.srchFilter.midName = this.midName;
                // this.srchFilter.minorName = this.minorName;

                if(this.midName.indexOf('1') == -1) {
                    this.midName = '1. ' + this.midName;
                    this.minorName = ' > 2. ' + this.minorName;
                    this.detailName = ' > 3. ' + this.detailName;
                    this.abilName = ' > 4. ' + this.abilName;
                }

                // console.log(11111222111111111, this.srchFilter);
            }
            // this.srchFilter.midName = this.midName;
            // this.srchFilter.minorName = this.minorName;
            // this.srchFilter.detailName = this.detailName;
            // this.srchFilter.abilName = this.abilName;
            param = this.srchFilter;

            param.pageUnit = '25';
            param.pageSize = '10';
            if(div) param.pageIndex = 1;

            // console.log('adsf',param);

            this.$.httpPost('/api/main/adm/ncsinout/getAbilUnitElemList', param)
				.then(res => {
                    
                    // console.log('getAbilUnitElemList RESULT', res);

                    this.abilUnitElemList = res.data.abilUnitElemList;
                    this.pageInfo = res.data.pageInfo;

				}).catch(this.$.httpErrorCommon);
        },

        //페이징 이동
        goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getAbilUnitElemList();
		},

        loadMid() {
            this.$.httpPost('/api/main/adm/ncsinout/loadMidList')
                .then(res => {
                    // console.log('loadMidList RESULT', res);
                    this.midNameList = res.data.loadMidList;

                    if(this.srchFilter.ncsMidSeq) this.loadMinor();
                }).catch(this.$.httpErrorCommon);
        },

        loadMinor() {
            if(this.srchFilter.ncsMidSeq == '') {
                this.srchFilter.ncsMinorSeq = '';
                this.minorNameList = [];
                if(this.srchFilter.ncsMinorSeq == '') {
                    this.srchFilter.ncsDetaSeq = '';
                    this.detailNameList = [];
                    if(this.srchFilter.ncsDetaSeq == '') {
                        this.srchFilter.ncsAbilSeq = '';
                        this.abilNameList = [];
                    }
                }
            } else {
                this.detailNameList = [];
                this.abilNameList = [];
                var params = {};
                params.midClassCd = this.srchFilter.ncsMidSeq;
                this.$.httpPost('/api/main/adm/ncsinout/loadMinorList' , params)
                    .then(res => {
                        // console.log('loadMinorList RESULT', res);
                        // console.log(22222);
                        // console.log(res);
                        this.minorNameList = res.data.loadMinorList;

                        if(this.srchFilter.ncsMinorSeq) this.loadDetail();

                    }).catch(this.$.httpErrorCommon);
            }
        },

        loadDetail() {
            if(this.srchFilter.ncsMinorSeq == '') {
                this.srchFilter.ncsDetaSeq = '';
                this.detailNameList = [];
                if(this.srchFilter.ncsDetaSeq == '') {
                        this.srchFilter.ncsAbilSeq = '';
                        this.abilNameList = [];
                    }
            } else {
                this.abilNameList = [];
                // this.srchFilter.ncsAbilSeq = '';
                var params = {};
                params.minorClassCd = this.srchFilter.ncsMinorSeq;

                this.$.httpPost('/api/main/adm/ncsinout/loadDetailList' , params)
                    .then(res => {
                        // console.log('loadDetailList RESULT', res);
                        // console.log(22222);
                        // console.log(res);
                        this.detailNameList = res.data.loadDetailList;

                        if(this.srchFilter.ncsDetaSeq) this.loadAbil();

                    }).catch(this.$.httpErrorCommon);
            }
        },

        loadAbil() {
            if(this.srchFilter.ncsDetaSeq == '') {
                this.srchFilter.ncsAbilSeq = '';
                this.abilNameList = [];
            } else {
                var params = {};
                params.detailClassCd = this.srchFilter.ncsDetaSeq;
                // console.log('params', params);

                this.$.httpPost('/api/main/adm/ncsinout/loadAbilList' , params)
                    .then(res => {
                        // console.log('loadAbilList RESULT', res);
                        // console.log(22222);
                        // console.log(res);
                        this.abilNameList = res.data.loadAbilList;

                        if(this.ncsAbilSeq != '') {
                            this.getAbilUnitElemList();
                        }
                        

                    }).catch(this.$.httpErrorCommon);
            }
        },

        check(cd) {
            if(cd == '') {
                this.midName = '';
                this.minorName = '';
                this.detailName = '';
                this.abilName = '';
            } else {
                for(var item in this.midNameList) {
                // console.log(item, '2', cd, this.midNameList[item].midClassCd);
                    if(this.midNameList[item].midClassCd == cd) {
                        this.midName = '1. ' + this.midNameList[item].midClassNm;
                    }
                }
                this.minorName = '';
                this.detailName = '';
                this.abilName = '';
            }
        },
        check1(cd) {
            if(cd == '') {
                this.minorName = '';
                this.detailName = '';
                this.abilName = '';
            } else {
                for(var item in this.minorNameList) {
                // console.log(item, '2', cd, this.midNameList[item].midClassCd);
                    if(this.minorNameList[item].minorClassCd == cd) {
                        this.minorName = ' > 2. ' + this.minorNameList[item].minorClassNm;
                    }
                }
                this.detailName = '';
                this.abilName = '';
            }
        },
        check2(cd) {
            if(cd == '') {
                this.detailName = '';
                this.abilName = '';
            } else {
                for(var item in this.detailNameList) {
                // console.log(item, '2', cd, this.midNameList[item].midClassCd);
                    if(this.detailNameList[item].detailClassCd == cd) {
                        this.detailName = ' > 3. ' + this.detailNameList[item].detailClassNm;
                    }
                }
                this.abilName = '';
            }
        },

        check3(cd) {
            if(cd == '') {
                this.abilName = '';
            } else {
                for(var item in this.abilNameList) {
                // console.log(item, '2', cd, this.midNameList[item].midClassCd);
                    if(this.abilNameList[item].abilUnitCd == cd) {
                        this.abilName = ' > 4. ' + this.abilNameList[item].abilUnitNm;
                    }
                }
            }
        },

        viewAbilElemDetail(elem) {
            this.srchFilter.midName = this.midName;
            this.srchFilter.minorName = this.minorName;
            this.srchFilter.detailName = this.detailName;
            this.srchFilter.abilName = this.abilName;
            var params = {
                abilElemSeq : elem.abilUnitElemCd,
                caller : {
                    name : this.$route.name,
                    params : this.srchFilter
                }
            }
            
            this.$router.push({name: 'MAN936M05', params });
        },

        viewSelfDiaQues(elem) {
            // console.log('elem', elem );
            this.srchFilter.midName = this.midName;
            this.srchFilter.minorName = this.minorName;
            this.srchFilter.detailName = this.detailName;
            this.srchFilter.abilName = this.abilName;
            var params = {
                midName : elem.midClassNm,
                minorName : elem.minorClassNm,
                detailName : elem.detailClassNm,
                abilName : elem.abilUnitNm,
                elemName : elem.abilUnitElemNm,
                elemSeq : elem.abilUnitElemCd,
                caller : {
                    name : this.$route.name,
                    params : this.srchFilter
                }
            }
            
            this.$router.push({name: 'MAN936M06', params});
        }
    },
    components : {
    pagingComp,
    SelectComp
},

}
</script>